







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConnectionList from '@/components/connection/ConnectionList.vue';
import ConnectionHeader from '@/components/connection/ConnectionHeader.vue';

@Component({
  components: {
    ConnectionList,
    ConnectionHeader,
  },
})
export default class ConnectionsView extends Vue {
}
