




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ConnectionForm from '@/components/connection/ConnectionForm.vue';

@Component({
  components: {
    ConnectionForm,
  },
})
export default class AddConnectionView extends Vue {

}
