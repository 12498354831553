
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ConnectionModel } from '../../models';
import ConfirmationDialog from '../shared/ConfirmationDialog.vue';
import OverlayLoader from '../shared/OverlayLoader.vue';
import { PermissionLevel } from '../../enums';
import UserService from '../../services/UserService';
import { ENVIRONMENT_TYPES } from '../../helpers/Constants';

@Component({
  components: {
    ConfirmationDialog,
    OverlayLoader,
  },
})
export default class ConnectionList extends Vue {
  readonly environments = ENVIRONMENT_TYPES;
  showDialog: boolean = false;
  selectedConnectionId: string;
  isLoading: boolean = false;

  @Watch('environment')
  onEnvironmentChange(value: string, old: string) {
    this.isLoading = true;
    this.$store.dispatch('loadConnection')
      .then(() => {
        this.isLoading = false;
      });
  }

  copyText(link: string): void {
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  get environment(): string {
    return this.$store.state.environment.toLowerCase();
  }

  get connections(): ConnectionModel[] {
    return this.$store.state.connections.sort((a: ConnectionModel, b: ConnectionModel) => {
      return a.name < b.name
        ? -1
        : a.name > b.name
          ? 1
          : 0;
    });
  }

  get canEdit(): boolean {
    return UserService.hasPermission(PermissionLevel.ReadWrite);
  }

  mounted(): void {
    this.isLoading = true;
    this.$store.dispatch('loadConnections')
      .then(() => {
        this.isLoading = false;
      });
  }

  editConnection(connection: ConnectionModel): void {
    this.$router.push(`../connections/${connection.id}/edit`);
  }

  confirmDelete(connection: ConnectionModel) {
    this.selectedConnectionId = connection.id;
    this.showDialog = true;
  }

  deleteConnection(): void {
    this.isLoading = true;
    this.$store.dispatch('deleteConnection', this.selectedConnectionId)
      .then(() => {
        this.isLoading = false;
      });
    this.selectedConnectionId = '';
    this.showDialog = false;
  }

  cancelDelete(): void {
    this.selectedConnectionId = '';
    this.showDialog = false;
  }
}
