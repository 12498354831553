































































import Vue from 'vue';
import Component from 'vue-class-component';
import OverlayLoader from '../shared/OverlayLoader.vue';
import { ConnectionModel } from '../../models';
import regex from '../../helpers/Regex';

@Component({
  components: {
    OverlayLoader,
  },
})
export default class ConnectionForm extends Vue {
  readonly items = ['AZURE', 'MSSQL', 'ORACLE', 'REDIS', 'MONGO', 'OTHER'];
  urlRegex: RegExp = new RegExp(regex.connectionString);
  connection: ConnectionModel = new ConnectionModel();
  initialConnection: string = '';
  isLoading: boolean = false;

  // use the data function until vuetify supports class properties
  data() {
    return {
      rules: {
        required: (value: string) => !!value || 'Required.',
        urlsRequired: (value: string) => !!value || 'Required.',
        validUrl: (value: string) => {
          if (value && value.length > 0) {
            return this.urlRegex.test(value) || 'Invalid url.';
          }
          return true;
        },
      },
    };
  }

  mounted(): void {
    this.isLoading = true;
    this.$store.dispatch('loadConnections')
      .then(() => {
        const connection = this.$store.getters.connectionById(
          this.$route?.params?.id,
        );
        if (connection) {
          this.connection = connection;
        }
        this.initialConnection = JSON.stringify(connection);
        this.isLoading = false;
      });
  }

  onSubmit(): void {
    const form: any = this.$refs.inputForm;
    if (!form.validate()) {
      return;
    }

    if (this.initialConnection === JSON.stringify(this.connection)) {
      return;
    }
    this.isLoading = true;
    const mode = this.connection.id
      ? { action: 'updateConnection', route: '../../connections' }
      : { action: 'saveConnection', route: '../connections' };

    this.$store.dispatch(mode.action, this.connection)
      .then((result: boolean) => {
        this.isLoading = false;
        if (result) {
          this.$router.push(mode.route);
        }
      });
  }
}
